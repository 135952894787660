
div.Work {
    margin-top: 45px;
}

div.flex-grid {
    display: flex;
  }
  div.col {
    flex: 1;
    padding: 25px;
  }

@media (max-width: 800px) {

    div.flex-grid {
        display: block;
    }

    div.col-card {
        margin: auto;
        max-width: 250px;
    }
}

p.col-card-desc {
    font-size: 14px;
    text-align: left;
    text-indent: 24px;
}

a, a:link, a:hover, a:visited, a:active {
    color: black;
}

div.col-card:hover {
    box-shadow: 0 0 45px rgba(0,0,0,0.2);
}


div.col-card {
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 25px;
    overflow: hidden;
    background: white;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    position: relative;
    margin-bottom: 45px;
    display: flex;
    flex-direction: column;
}

div.col-card:hover {
    cursor: pointer;
}

img.col-card-ccimg {
    max-width: 200px;
    margin: auto;
}