div.Service {
    margin-top: 90px;
}

div.services-trail {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

div.trail-item {
    max-width: 1000px;
}

h1.service {
    margin-top: 45px;
    padding: 20px;
    font-size: 52px;
    text-align: center;
}

h1.title {
    margin-top: 45px;
    font-size: 42px;
    text-align: center;
}

div.border {
    border-top: 1.5px solid lightgray;
    margin-left: 45px;
    margin-right: 45px;
    padding: 5px;
}

div.border-double {
    border-top: 2px double darkgray;
    margin-left: 45px;
    margin-right: 45px;
}

div.detail {
    font-size: 28px; /* Adjust the font size as desired */
    text-align: center;
    margin-top: 10px;
}
