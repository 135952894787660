div.Landing {
    background-image: linear-gradient(
    45deg,
    hsl(196deg 80% 51%) 0%,
    hsl(190deg 65% 59%) 33%,
    hsl(176deg 50% 54%) 50%,
    hsl(154deg 46% 54%) 67%,
    hsl(122deg 41% 58%) 100%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

div.logo-box {
  display: flex;
  align-items: left;
  justify-content: left;
  text-align: center;
}

img.logo-box {
  width: 125px;
  height: auto;
  padding: 45px;
}

div.primaryContent {
  margin-top: 0;
}

div.card {
  margin: 0 auto 0 auto;
  background-color: whitesmoke;
  border-radius: 3rem;
  max-width: 80vw;
  opacity: 75%;
  box-shadow: var(--shadow-elevation-medium);
  padding: 20px;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  flex-direction: column;
  justify-content: top;
  align-items: center;
}

div.card-text {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 40px;
  font-size: 38px Helvetica;
}


p.card-text {
  color: #506FCC; 
  /* @import url('https://fonts.googleapis.com/css2?family=Charm&display=swap'); */
  /* font-family: 'Charm', cursive;
   */
  line-height: 36px;
  margin: 10px 0 0 10px;
}

p {
  font-size: 48px Helvetica;
}

div.heroDetail {
  box-shadow: none;
}