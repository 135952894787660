
div.header {
    background-image: linear-gradient(
        90deg,
        hsl(196deg 80% 51%) 0%,
        hsl(190deg 65% 59%) 33%,
        hsl(176deg 50% 54%) 50%,
        hsl(154deg 46% 54%) 67%,
        hsl(122deg 41% 58%) 100%
      );
    position: fixed;
    top: 0; 
    width: 100%;
    min-height: 45px;
    color: darkslategray;
    z-index: 1000;
}
div.flexContainer {
    min-height: 65px;
    margin-left: 5%;
    margin-right: 45px;
    display: flex;
    justify-content: left;
}

div.flexContainer div.flexLink {
    justify-content: right;
}

div.flexLink {
    align-items: center;
    justify-content: center;
    max-width: 100px;
    font-weight: bold;
    flex: 1;
}

img.logo {
    width: 50px;
    height: auto;
    flex: 0;
    justify-content: left;
}
