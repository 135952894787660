div.heroBlock {
    font: 14pt Helvetica;
    display: flex;
    padding: 5px 25px 15px 25px;
    border-radius: 1rem;
    flex-direction: column;
    text-align: center;
}

div.heroStatement {
    font-size: 24px;
    border-radius: 1rem;
    width: 40%;
    margin: auto;
    padding: 5px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

div.heroIcon {
    font-size: 24px;
    margin: auto;
    padding: 5px;
    font-weight: 700;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    align-content: center;
}

div.heroDescription {
    font-size: 18px Helvetica;
    width: 60%;
    margin: auto;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

div.heroDetail {
    font-size: 14px;
    border-radius: 0.25rem;
    width: 90%;
    /* background-color: #fcfcfc; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: var(--shadow-elevation-low);
    transition: height 0.5s, color 0.5s, opacity 0.5s;
    overflow: hidden;
    max-height: 200px;
}

img.heroIconImg {
    width: 75px;
}

@media screen and (min-width: 800px) {
    div.heroBlock {
        flex-direction: row;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    div.heroStatement,
    div.heroDescription {
        align-items: left;
        justify-content: left;
        text-align: left;
    }
    div.heroIcon {
        flex-direction: row;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 10px 50px 10px 10px;
    }
}
