.product {
  /* Add styling for the entire product page */
}

.hero {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
        90deg,
        hsl(196deg 80% 51%) 0%,
        hsl(190deg 65% 59%) 33%,
        hsl(176deg 50% 54%) 50%,
        hsl(154deg 46% 54%) 67%,
        hsl(122deg 41% 58%) 100%
      );
  padding: 10px 10px;
}

.hero-label {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
}

.hero-text {
  font-size: 2rem; /* Adjust the font size for responsiveness */
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.2; /* Increase the line height to prevent overlapping */
}

.hero-description {
  font-size: 2rem; /* Adjust the font size for responsiveness */
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.2; /* Increase the line height to prevent overlapping */
}

.hero-button {
  margin-top: 15px;
  font-size: 1.2rem; /* Adjust the font size for responsiveness */
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.reasons-to-believe {
  height: 25vh; /* Adjust the height using viewport height */
  margin: 0 15%; /* Adjust the margins for responsiveness */
  line-height: 1.2; /* Increase the line height to prevent overlapping */
  font-size: 2.5rem; /* Adjust the font size for responsiveness */
  padding: 50px 5px 50px 5px;
  text-align: left;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 20px;
  /* Add additional styling for the feature grid */
}

@media screen and (max-width: 600px) {
  .feature-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(8, 1fr);
  }
}


.feature-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f5f5f5;
  padding: 20px;
  /* Add additional styling for the feature block */
}

.reasons-to-believe {
  margin: 0 15%;
  text-align: center;
  line-height: 1.2;
  font-size: 24px;
  height: auto;
  margin-bottom: 20px;
}

.feature-icon {
  width: 50px;
  height: 50px;
  /* Add additional styling for the feature icon */
}

.feature-title {
  font-size: 1.2rem; /* Adjust the font size for responsiveness */
  /* Add additional styling for the feature title */
}

.feature-description {
  font-size: 1rem; /* Adjust the font size for responsiveness */
  /* Add additional styling for the feature description */
}
