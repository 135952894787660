div.Contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

div.Contact h1 {
  margin: auto;
}

div.form-group {
  padding: 5px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

div.form-group label {
  flex: 0;
}

div.form-group textarea {
  flex: 1;
  font: inherit;
  color: #4D4D4D;
}

div.form-group input {
  flex: 1;
  font: inherit;
  color: #4D4D4D;
}

div.error {
  text-align: center;
}

div.button {
  justify-content: flex-end;
  text-align: right;
}

div.btn {
  box-shadow: var(--shadow-elevation-medium);
}

label {
  margin-bottom: 10px;
}

.error-message {
  color: red;
}

.error .MuiInputBase-root {
  border-color: red;
}

@media screen and (max-width: 600px) {
  div.form-group {
    min-width: 250px;
  }
}

@media screen and (max-width: 400px) {
  div.form-group {
    min-width: 200px;
  }
}
