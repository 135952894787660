body {
    font-size: 12pt;
    font-family:Arial, Helvetica, sans-serif;
    line-height: 14pt;
    color: black;
    margin: 0;
}

h1 {
    font: 28pt Helvetica;
    font-weight: bold;
    line-height: 20pt;
}

h2 {
    font: 24pt Helvetica;
    font-weight: lighter;
}

p {
    font: 14pt Helvetica;
    line-height: 16pt;
}


p.subheader {
    font-weight: bold;
    color: #593d87
}

img {
    padding: 3pt;
    float: right;
}

a {
    text-decoration: none;
}

a:link, a:visited {
    color: #8094d6
}

a:hover, a:active {
    color: #FF9933
}

div.primaryContent {
    font: 10pt Helvetica;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}


div.secondaryContent {
    font: 14pt Helvetica;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #67BFD2; */
    background-image: linear-gradient(
        90deg,
        hsl(196deg 80% 51%) 0%,
        hsl(190deg 65% 59%) 33%,
        hsl(176deg 50% 54%) 50%,
        hsl(154deg 46% 54%) 67%,
        hsl(122deg 41% 58%) 100%
      );
    flex-direction: column;
}


div.contactForm {
    padding: 125px 0 0 0;
    min-height: 600px;
    border-radius: 1%;
}



div.footer {
    font: 9pt Helvetica;
    text-align: center;
    padding: 75px 0 25px 0;
}

/* :root {
    --shadow-color: 0deg 0% 55%;
    --shadow-elevation-low:
      -0.3px -0.3px 0.4px hsl(var(--shadow-color) / 0.49),
      -0.5px -0.4px 0.7px -1.5px hsl(var(--shadow-color) / 0.44),
      -1.2px -1.1px 1.7px -2.9px hsl(var(--shadow-color) / 0.39);
    --shadow-elevation-medium:
      -0.3px -0.3px 0.4px hsl(var(--shadow-color) / 0.41),
      -0.7px -0.6px 1px -0.7px hsl(var(--shadow-color) / 0.39),
      -1.4px -1.3px 2px -1.5px hsl(var(--shadow-color) / 0.36),
      -3.1px -2.8px 4.4px -2.2px hsl(var(--shadow-color) / 0.34),
      -6.1px -5.6px 8.8px -2.9px hsl(var(--shadow-color) / 0.31);
    --shadow-elevation-high:
      -0.3px -0.3px 0.4px hsl(var(--shadow-color) / 0.42),
      -1.4px -1.3px 2px -0.4px hsl(var(--shadow-color) / 0.41),
      -2.7px -2.5px 3.9px -0.7px hsl(var(--shadow-color) / 0.4),
      -4.4px -4px 6.3px -1.1px hsl(var(--shadow-color) / 0.38),
      -6.9px -6.4px 10px -1.5px hsl(var(--shadow-color) / 0.37),
      -10.7px -9.8px 15.3px -1.8px hsl(var(--shadow-color) / 0.35),
      -16.1px -14.8px 23.1px -2.2px hsl(var(--shadow-color) / 0.34),
      -23.4px -21.5px 33.6px -2.6px hsl(var(--shadow-color) / 0.32),
      -33.2px -30.5px 47.7px -2.9px hsl(var(--shadow-color) / 0.31);
  } */

:root {
    --shadow-color: 0deg 0% 0%;
    --shadow-elevation-low:
      0.3px 0.5px 0.6px hsl(var(--shadow-color) / 0.15),
      0.4px 0.8px 0.9px -1.8px hsl(var(--shadow-color) / 0.12),
      1px 2px 2.2px -3.5px hsl(var(--shadow-color) / 0.09);
    --shadow-elevation-medium:
      0.3px 0.5px 0.6px hsl(var(--shadow-color) / 0.16),
      0.7px 1.3px 1.4px -1.2px hsl(var(--shadow-color) / 0.13),
      1.9px 3.8px 4.1px -2.4px hsl(var(--shadow-color) / 0.11),
      5.1px 10.1px 10.9px -3.5px hsl(var(--shadow-color) / 0.09);
    --shadow-elevation-high:
      0.3px 0.5px 0.6px hsl(var(--shadow-color) / 0.15),
      1px 2px 2.2px -0.5px hsl(var(--shadow-color) / 0.13),
      2px 3.9px 4.2px -1px hsl(var(--shadow-color) / 0.12),
      3.6px 7.2px 7.8px -1.5px hsl(var(--shadow-color) / 0.11),
      6.3px 12.6px 13.6px -2px hsl(var(--shadow-color) / 0.1),
      10.6px 21.1px 22.8px -2.5px hsl(var(--shadow-color) / 0.09),
      16.8px 33.6px 36.3px -3px hsl(var(--shadow-color) / 0.08),
      25.5px 51px 55.2px -3.5px hsl(var(--shadow-color) / 0.07);
  }