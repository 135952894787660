div.About {
  margin-top: 45px;
}

div.about-content {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  text-align: left;
}


div.about-content-item {
  padding: 25px;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: #C1E3E1;
  max-width: 75vw;
  border-radius: 1rem;
  box-shadow: var(--shadow-elevation-medium);
}

h2.about-content-header {
  margin: auto;
  text-align: center;
  line-height: 40px;
  padding: 40px 0px;
}

div.about-content-body {
  margin-left: 10px;
}

div.about-content-body p {
  font-size: 18px;
  line-height: 32px;
  text-indent: 24px;
}

ul {
  /* list-style-image: url(./src/assets/icon-chevron-right.svg); */
  padding: 5px;
  font-size: 12px Helvetica;
}

li {
  font: 12pt Helvetica;
  line-height: 26pt;
  margin-left: 5px;
}

li::marker {
  font-size: 1rem;
}

div.about-primary {
  display: flex;
  flex-direction: row;
  justify-content: left;
  text-align: left;
  padding: 20px;
}

div.about-secondary {
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

img.profile-pic {
  width: 250px;
  /* max-width: 40vw; */
  padding: 0 50px 0 50px;
} 

div.about-image {
  align-items: center;
  display: flex;
  text-align: center;
  flex-direction: column;
}

h3.profile-pic-title {
  margin: auto;
}

div.about-desc {
  text-align: left;
  display: flex;
}

p.about-desc-para {
  margin: auto;
  font-size: 16px;
  padding: 0 3em 0 3em;
}


div.about-specialties {
  font-size: 12px;
  line-height: 26px;
  padding: 0 25px 0 25px;
  margin: 0 45px 0 45px;
  justify-content: left;
  align-items: flex-start;
  text-align: left;

  display: flex;
  flex-direction: row;
}

div.spec-group {
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
  padding: 10px;
}


@media screen and (max-width: 700px) {
    div.about-primary, div.about-secondary {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    div.about-specialties {
      flex-direction: column;
    }

    div.spec-group {
      margin: 0;
      padding: 0;
      max-width: none;
    }
    h2 {
      text-align: center;
      line-height: 40px;
    }

}

.ticker {
  padding: 20px 100px 20px 100px;
}

.quote {
  color: #444444;
  font-family: Arial;
  font-size: 20px;
  font-weight: bold;
  /* margin-bottom: 0 50px 10px 50px; */
  max-width: 450px;
  text-align: left;
}

.source {
  color: #1a1a25;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: normal;
  text-align: right;
}
